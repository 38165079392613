import React from "react";

// Layout
import SecondaryLanding from "../../../layouts/secondary-landing";

// Components
import BreadcrumbAuto from "../../../components/breadcrumb/breadcrumb-auto";
import SEO from "../../../components/seo/seo";
import ArticleCategoriesSection from "../../../components/blog-categories/article-categories-section";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

import { banking101Cards } from "../../../data/blog-cards-data";

const category = {
  id: "Article-categories_2",
  Name: "Banking 101",
  Slug: "banking-101",
  articles: banking101Cards,
  SEO: {
    MetaDescription:
      "Read about the top banking articles for beginners from WaFd Bank in our top banking 101 articles including how to switch banks and how to open a bank account.",
    MetaTitle: "Banking Articles for Beginners"
  }
};

const Banking101Index = ({ location }) => {
  const pageLocation = { location };
  const categoryName = category.Name;

  const metaTitle = category.SEO && category.SEO.MetaTitle;
  const metaDescription = category.SEO && category.SEO.MetaDescription;

  const SEOData = {
    title: metaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: metaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: metaDescription
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/blog/" + category.Slug
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <BreadcrumbAuto {...pageLocation} />
      <SEO {...SEOData} />

      <ArticleCategoriesSection categoryName={categoryName} articles={category.articles} />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default Banking101Index;

